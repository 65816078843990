<template>
    <div>
      <!-- <p class="text-right text-h5">Welcome To Smart city Data Platform.</p> -->
      <!-- <v-divider class="mb-6"></v-divider> -->
      <v-carousel height="460" cycle>
      <v-carousel-item>
        <v-img
          height="100%"
          src="@/assets/hexagon1.svg"
        >
          <v-row>
              <v-col cols="6" align-self="center" offset="1">
                <div class="text-h2 white--text text--lighten-1">
                  HTTP Endpoint
                </div>
                <div class="text-h6 white--text text--lighten-4">
                  In cloud HTTP Endpoint, which is an ingress for DARA with custom path, authentication and request parser.  HTTP is a standard protocol which is virtually available everywhere.
                </div>
              </v-col>
              <v-col cols="5" align-self="end">
                <v-img src="@/assets/http.png" contain height="200" aspect-ratio="1" />
              </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
      <v-carousel-item>
        <v-img
          height="100%"
          src="@/assets/hexagon1.svg"
        >
          <v-row>
              <v-col cols="6" align-self="center" offset="1">
                <div class="text-h2 white--text text--lighten-1">
                  Task pipeline
                </div>
                <div class="text-h6 white--text text--lighten-4">
                  When incoming requests come, Each of them are processed in the pipeline.
                  Those pipelines can arrange parallel task groups in sequential order where following tasks receive manipulated results from prior tasks.
                </div>
              </v-col>
              <v-col cols="5" align-self="end">
                <v-img src="@/assets/workflow.png" contain height="200" aspect-ratio="1" />
              </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
      <v-carousel-item>
        <v-img
          height="100%"
          src="@/assets/hexagon1.svg"
        >
          <v-row>
              <v-col cols="6" align-self="center" offset="1">
                <div class="text-h2 white--text text--lighten-1">
                  Template
                </div>
                <div class="text-h6 white--text text--lighten-4">
                  Allow developer to config task and payload data via template language.
                  The parsed request data including request payload can extract and used in task configuration Jinja template language.
                </div>
              </v-col>
              <v-col cols="5" align-self="end">
                <v-img src="@/assets/Jinja_software_logo.svg" contain height="200" aspect-ratio="1" />
              </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
      <v-carousel-item>
        <v-img
          height="100%"
          src="@/assets/hexagon1.svg"
        >
          <v-row>
              <v-col cols="6" align-self="center" offset="1">
                <div class="text-h2 white--text text--lighten-1">
                  Common Utility Tasks
                </div>
                <div class="text-h6 white--text text--lighten-4">
                  We provide a collection of tasks which are common to work with income requests such as Aggregator, Iterator, HTTP Request and S3Keeper.
                  Those tasks are used to manipulate and store HTTP request data to satisfy developer requirements.
                </div>
              </v-col>
              <v-col cols="5" align-self="end">
                <v-img src="@/assets/tool-box.png" contain height="200" aspect-ratio="1" />
              </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
      <v-carousel-item>
        <v-img
          height="100%"
          src="@/assets/hexagon1.svg"
        >
          <v-row>
              <v-col cols="6" align-self="center" offset="1">
                <div class="text-h2 white--text text--lighten-1">
                  Fiware
                </div>
                <div class="text-h6 white--text text--lighten-4">
                  FIWARE is a curated framework of Open Source platform components to accelerate the development of Smart Cities, Smart Industry, Smart Agrifood, Smart Water and Smart Energy.
                </div>
              </v-col>
              <v-col cols="5" align-self="end">
                <v-img src="@/assets/fiware_logo.png" contain height="200" aspect-ratio="1" />
              </v-col>
          </v-row>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <v-row class="mt-16">
      <v-col cols="6" v-for="card in cards" :key="card.title">
        <v-card class="mx-auto">
          <v-img src="@/assets/RectLight.svg">
            <v-container>
              <v-row>
                <v-col>
                  <v-img :src="card.image" height="150" contain />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card-title class="white--text ml-3">{{card.title}}</v-card-title>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
          <v-card-text>
            {{card.subtitle}}
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" :href="card.path" target="_blank">
              READ MORE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
      <!-- <empty-page></empty-page> -->
    </div>
</template>

<script>
// @ is an alias to /src
// import EmptyPage from '@/components/EmptyPage.vue'

export default {
  name: 'Home',
  components: {},
  data: () => ({
    reveal: false,
    show: false,
    cards: [
      {
        title: 'Getting Started',
        color: 'green',
        subtitle: 'END-POINT is Application Programming Interface (API) allows two systems to communicate with one another.',
        path: 'https://docs.meca.in.th/mep/gettingStarted.html',
        image: 'mep-diagram.png',
        subpath: []
      },
      {
        title: 'HTTP transform and forward',
        subtitle: 'Transform incoming request by Jinja template then forward those transformed request to third party HTTP Endpoint',
        image: 'Jinja_software_logo.svg',
        path: 'https://docs.meca.in.th/mep/jinjaTemplate.html',
        subpath: []
      },
      {
        title: 'Timeout window aggregation',
        subtitle: 'Transform incoming request by Jinja template then forward those transformed request to third party HTTP Endpoint',
        image: 'timeout.aggregate.png',
        path: 'https://docs.meca.in.th/mep/windowAggreator.html',
        subpath: []
      },
      {
        title: 'Iterator',
        subtitle: 'Transform incoming request by Jinja template then forward those transformed request to third party HTTP Endpoint',
        image: 'iterator.png',
        path: 'https://docs.meca.in.th/mep/iterator.html',
        subpath: []
      },
      {
        title: 'Save to S3',
        subtitle: 'Store binary payload to S3',
        image: 'save2s3.png',
        path: 'https://docs.meca.in.th/mep/binaryS3.html',
        subpath: []
      }
    ],
    guardVal: null
  }),
  computed: {
    breadcrumb: function () {
      const path = this.$route.path.split('/').filter((pf) => pf)
      const items = []
      path.forEach((p, index) => {
        let pathLink = ''
        for (let i = 0; i <= index; i++) {
          pathLink = pathLink.concat(`/${path[i]}`)
        }
        items.push({
          text: p,
          disabled: false,
          to: pathLink
        })
      })
      return items
    }
  }
}
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
